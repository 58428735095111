import React from "react";
import { Card, Container, Row, Col, Badge } from "react-bootstrap";
import "./profile.css";
const Profile: React.FC = () => {
  const skills = [
    "Python",
    "TypeScript",
    "JavaScript",
    "Clojure",
    "Django",
    "FastAPI",
    "React",
    "Redux",
    "Three.js",
    "REST",
    "GraphQL",
    "PostgreSQL",
    "Redis",
    "Rabbitmq",
    "DynamoDB",
    "AWS",
    "Terraform",
    "Docker",
    "Linux",
    "ElasticSearch",
    "Datomic",
  ];

  const experiences = [
    {
      duration:
        "Jan 2024 - Present: Consultant Developer | 3DBolig, Oslo, Norway",
      details: [
        {
          description:
            "Led a project to aggregate and search municipal regulations for all communities in Norway. Utilized Python, React, TypeScript, RabbitMQ, and PostgreSQL to query regulations based on addresses. Integrated Large Language Models (LLMs) to interpret regulatory documents for customers.",
        },
        {
          description:
            "Successfully expanded the Cabin Configurator project to incorporate modern-styled roofs and walls. Leveraged Three.js for advanced 3D rendering, Django for robust backend support, and React for dynamic frontend interfaces. Enhanced the configurator’s functionality and aesthetics, improving user experience and customization options.",
        },
      ],
      skills: [
        "Python",
        "TypeScript",
        "Django",
        "React",
        "Redux",
        "Three.js",
        "REST",
        "PostgreSQL",
        "Rabbitmq",
        "Docker",
      ],
    },

    {
      duration:
        "Jun 2023 – Jul 2024: Developer | Raphta IOT, Midrand South Africa",
      details: [
        {
          description:
            "Primarily focused on an IoT project centered around critical infrastructure monitoring. The platform monitors over a thousand sites. This project utilizes AWS Lambda, AWS API Gateway, Amplify AWS Kinesis, RDS, Python, Terraform, DynamoDB, and TypeScript. Additionally, I supported the development of an agricultural marketplace, utilizing a similar tech stack.",
        },
      ],
      skills: [
        "Python",
        "TypeScript",
        "Django",
        "React",
        "Redux",
        "GraphQL",
        "PostgreSQL",
        "AWS",
        "Terraform",
        "Docker",
      ],
    },
    {
      duration: "Aug 2022 - Jun 2023: Consultant | Glace Tech AS, Oslo, Norway",
      details: [
        {
          description:
            "Maintaining and expanding the Oiiku Platform, based on Clojure, Datomic, React, and ElasticSearch.",
        },
        {
          description:
            "Using Python to create an API to integrate Odoo to One Drive and Tripletex.",
        },
        {
          description:
            "Created a 2D collaboration tool for Novi, a project management application based on Clojure, JavaScript, and ClojureScript.",
        },
      ],
      skills: ["Clojure", "Terraform", "ElasticSearch", "Datomic"],
    },
    {
      duration: "Oct 2019 - Aug 2022: Consultant | Inonit AS, Oslo, Norway",
      details: [
        {
          description:
            "Maintaining a Three JS, Django, React-based Garage Configurator with over 39500 customers and over 439800 projects, providing excellent and quick feature implementation service to ensure customer retention.",
        },
        {
          description:
            "Collaborated with 2 team members in a fast-paced environment, using PostgreSQL Django Rest Framework and React to implement an SMS gateway to serve 2 businesses that use our systems.",
        },
        {
          description:
            "Provided students at the University of Oslo with a fast logic-driven system for studying addiction. The system serves an average of 250 users daily and over 11,000 users. Upgraded the system from Python 2 to Python 3. Solved the backward compatibility issue related to the changes in Celery, brought about by upgrading to Python 3.",
        },
        {
          description:
            "Worked with a team to implement a teaching-aid application, for Norwegian Kindergarten pupils to learn pronunciation using Clojure and Google Speech to Text.",
        },
      ],
      skills: [
        "Python",
        "JavaScript",
        "Clojure",
        "Django",
        "React",
        "Redux",
        "Three.js",
        "REST",
        "PostgreSQL",
      ],
    },
  ];

  return (
    <Container fluid>
      <Row className="justify-content-center align-items-center">
        <Col xs={12} md={4} lg={6}>
          <Card className="custom-card shadow-lg mb-4">
            <Card.Body>
              <Card.Title>HEARTMAN SIBANDA</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                I N T R O
              </Card.Subtitle>
              <Card.Text>
                Experienced, passionate Software Developer who enjoys designing,
                developing, maintaining, and debugging software applications. A
                good team player, who can fly solo comfortably when needed.
                Always learning new skills and trends.
              </Card.Text>
              <Card.Subtitle className="mb-2 text-muted">
                E D U C A T I O N
              </Card.Subtitle>
              <Card.Text>
                Honors Degree in Electrical Engineering, University of Zimbabwe
                - Harare
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="justify-content-center align-items-center">
        <Col xs={12} md={4} lg={6}>
          <Card className="custom-card shadow-lg mb-4">
            <Card.Body>
              <Card.Title>S K I L L S </Card.Title>
              <Card.Text>
                {skills.map((skill, index) => (
                  <Badge key={index} pill bg="secondary" className="m-1">
                    {skill}
                  </Badge>
                ))}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="justify-content-center align-items-center">
        <Col xs={12} md={4} lg={6}>
          <Card className="custom-card shadow-lg mb-4">
            <Card.Body>
              <Card.Title> E X P E R I E N C E</Card.Title>
            </Card.Body>
          </Card>
          {experiences.map((exp, index) => (
            <Card className="custom-card shadow-lg mb-4" key={index}>
              <Card.Body>
                <Card.Title> </Card.Title>
                <div className="mb-3">
                  <Card.Subtitle className="text-secondary">
                    {exp.duration}
                  </Card.Subtitle>
                  {exp.details.map((detail, idx) => (
                    <Card.Text key={idx} className="mb-1">
                      {detail.description}
                    </Card.Text>
                  ))}
                </div>
                {exp.skills.map((skill, index) => (
                  <Badge key={index} pill bg="secondary" className="m-1">
                    {skill}
                  </Badge>
                ))}
              </Card.Body>
            </Card>
          ))}
        </Col>
      </Row>
    </Container>
  );
};

export default Profile;
