import React from "react";
import { Container } from "react-bootstrap";
import "./App.css";
import Profile from "./components/Profile";
import Footer from "./components/Footer";

const App: React.FC = () => {
  return (
    <div className="d-flex flex-column min-vh-100">
      <Container className="mt-5 mb-5 flex-grow-1">
        <Profile />
      </Container>
      <Footer />
    </div>
  );
};

export default App;
