import React from "react";
import { Container } from "react-bootstrap";
import { Gitlab, Github } from "react-bootstrap-icons";

const Footer = () => {
  return (
    <footer className="text-light py-2">
      <Container>
        <div className="d-flex justify-content-center align-items-center">
          <a
            href="https://gitlab.com/sibandaheartman"
            target="_blank"
            rel="noopener noreferrer"
            className="text-light me-3"
          >
            <Gitlab size={20} />
          </a>
          <a
            href="https://github.com/HeartmanTalent"
            target="_blank"
            rel="noopener noreferrer"
            className="text-light"
          >
            <Github size={20} />
          </a>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
